import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import styled from "@emotion/styled";
import NameDescription from "./NameDescription";
import HeaderWithUnderline from "./HeaderWithUnderline";
import RejectAdminEnquiryModal from "./RejectAdminEnquiryModal";
import EditAdminEnquiryModal from "./EditAdminEnquiryModal";
import { rejectAdminEnquiryById } from "../../../../api/Admin/adminEnquiries";
import EditIcon from "@mui/icons-material/Edit";
import useToast from "../../../../hooks/useToast";
import moment from "moment";

const BoxContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  paddingTop: "16px",
  paddingLeft: "32px",
  paddingRight: "32px",
}));

const StyledBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const StyledTypography = styled(Typography)(({ color }) => ({
  display: "inline",
  color: color || "#FF3F3F",
  fontSize: "14px",
  fonWeight: "700",
  marginLeft: "40px",
  cursor: "pointer",
}));

export default function EnquiryDetails(props) {
  const { enquiry, refreshEnquiryDetails } = props;
  const { handleOpenToast } = useToast();
  const enquiryStatusArray = ["UNQUOTED", "QUOTED", "NO_SERVICE"];

  const [isRejectEnquiryModalOpen, setIsRejectEnquiryModalOpen] =
    useState(false);
  const [isEditEnquiryModalOpen, setIsEditEnquiryModalOpen] = useState(false);

  const handleRejectEnquiry = async () => {
    try {
      const response = await rejectAdminEnquiryById(
        enquiry?.customerEnquiryId,
        { ignoreEnquiry: true }
      );
      if (response.status === 200) {
        setIsRejectEnquiryModalOpen(false);
        refreshEnquiryDetails();

        handleOpenToast("Enquiry Rejected successfully!", "error");
      }
    } catch (error) {
      handleOpenToast("Error while updating enquires", "error");
    }
  };

  return (
    <Box>
      <HeaderWithUnderline header="Enquiry Details" />

      <StyledBox>
        <BoxContainer marginLeft="0px" gap="40px">
          <NameDescription
            name="Pick Up Location"
            description={enquiry?.pickup?.location?.address}
            marginLeft="0px"
            width="220px"
          />
          <NameDescription
            name="Distance"
            description={`${enquiry?.distance} Km`}
            marginLeft="0px"
          />

          <NameDescription
            name="Drop Off Location"
            description={enquiry?.dropoff?.location?.address}
            marginLeft="0px"
            width="220px"
          />
        </BoxContainer>

        <Box
          sx={{
            display: "flex",
            flexDirection: "Column",
            marginRight: "30px",
            gap: "12px",
          }}
        >
          {enquiryStatusArray.includes(enquiry.enquiryStatus) && (
            <StyledBox
              onClick={(e) => {
                setTimeout(() => {
                  setIsEditEnquiryModalOpen(true);
                }, 100);
              }}
            >
              <StyledTypography
                color="#5386E4"
                textAlign="right"
                fontWeight="700"
              >
                Edit Enquiry
              </StyledTypography>
              <EditIcon sx={{ fill: "#5386E4", cursor: "pointer" }} />
            </StyledBox>
          )}

          {enquiryStatusArray.includes(enquiry.enquiryStatus) && (
            <Box
              sx={{
                display: "flex",
                justifyItems: "center",
                alignItems: "center",
              }}
            >
              <StyledTypography
                onClick={() => setIsRejectEnquiryModalOpen(true)}
              >
                Reject Enquiry
              </StyledTypography>
              <img
                src="/assets/icons/RejectIcon.svg"
                alt="addImage"
                style={{
                  height: "16px",
                  width: "16px",
                  marginLeft: "8px",
                }}
              />
            </Box>
          )}
        </Box>
      </StyledBox>

      <Box
        sx={{
          marginTop: "32px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <NameDescription
          name="Enquiry Date"
          description={
            (enquiry?.createdAt &&
              moment(enquiry?.createdAt).format("DD-MM-YYYY")) ||
            "-"
          }
        />
        <NameDescription
          name="Pickup Date"
          description={
            (enquiry?.pickup?.date &&
              moment(enquiry?.pickup?.date).format("DD-MM-YYYY")) ||
            "-"
          }
          marginLeft="40px"
        />
        <NameDescription
          name="Pickup Time"
          description={enquiry?.pickup?.slot || "-"}
          marginLeft="40px"
        />
        <NameDescription
          name="Material Type"
          description={enquiry?.material?.name || "-"}
          marginLeft="40px"
        />
        <NameDescription
          name="Goods weight"
          description={
            enquiry?.weight ? `${enquiry?.weight / 1000} Tons` : " - "
          }
          marginLeft="40px"
        />
        <NameDescription
          name="Vehicle category"
          description={
            enquiry?.customer?.preferredVehicle?.vehicleCategory
              ? `${enquiry?.customer?.preferredVehicle?.vehicleCategory} Wheeler`
              : "-"
          }
          marginLeft="40px"
        />
      </Box>

      <RejectAdminEnquiryModal
        setIsRejectEnquiryModalOpen={setIsRejectEnquiryModalOpen}
        enquiry={enquiry}
        handleRejectEnquiry={handleRejectEnquiry}
        isRejectEnquiryModalOpen={isRejectEnquiryModalOpen}
      />

      <EditAdminEnquiryModal
        setIsEditEnquiryModalOpen={setIsEditEnquiryModalOpen}
        enquiry={enquiry}
        refreshEnquiryDetails={refreshEnquiryDetails}
        isEditEnquiryModalOpen={isEditEnquiryModalOpen}
      />
    </Box>
  );
}
