import { adminApi } from "../config";

export const getAllDriverLocation = async (transporterId) => {
  try {
    const response = adminApi.get(
      `admin/transporter/${transporterId}/drivers/locations`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getDriverLocation = async (driverId) => {
  try {
    const response = adminApi.get(`admin/driver/${driverId}/location`);
    return response;
  } catch (error) {
    return error;
  }
};

export const fetchAllTransporters = async(transporter)=>{
  return await adminApi.get(`admin/transporters?search=${transporter}`)
}

export const fetchAllDrivers = async(driver)=>{
  return await adminApi.get(`admin/drivers?search=${driver}`)
}


