import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { createTransaction } from "../../../api/Admin/trips";
import styled from "@emotion/styled";
import { PAYMENT_METHOD } from "../../../constants/constant";

const SettlePaymentModal = ({
  isModalOpen,
  handleCloseModal,
  tripData,
  userType,
  handleOpenToast,
}) => {
  const [selectedPaymentModes, setSelectedPaymentModes] = useState("");

  const [error, setError] = useState("");
  const PAYMENT_MODES = {
    'UPI':'upi',
    'CASH':'cash',
    'CARD':'card',
    'Netbanking':'netbanking',
    'WALLET':'wallet'
  }

  const [amountPaid, setAmountPaid] = useState(
    userType === "customer"
      ? tripData?.customerAmounts?.balanceReceivable
      : tripData?.transporterAmounts?.balancePayable
  );

  // Change handler for the Select component
  const handlePaymentModeChange = (event) => {
    setSelectedPaymentModes(event.target.value);
  };

  const handleCreateTransaction = async () => {
    try {
      if (
        userType === "customer" &&
        Math.ceil(amountPaid) >
          Math.ceil(tripData?.customerAmounts?.balanceReceivable)
      ) {
        setError("Please enter less than or equal to payable amount");
        return;
      }
      if (
        userType === "transporter" &&
        Math.ceil(amountPaid) >
          Math.ceil(tripData?.transporterAmounts?.balancePayable)
      ) {
        setError("Please enter less than or equal to payable amount");
        return;
      }
      const data = {
        amount: amountPaid,
        paymentId:'',
        tripId: tripData?.tripId,
        customerEnquiryId: tripData?.enquiryDetails?.enquiryId,
        senderId:
          userType === "customer"
            ? tripData?.customerDetails?.customerId
            : "LE_PLATFORM",
        receiverId:
          userType === "customer"
            ? "LE_PLATFORM"
            : tripData?.transporterDetails?.transporterId,
        isPaymentDoneThroughLEPlatform: false,
        transactionStatus: "SUCCESS",
        paymentType: userType === "customer" ? "RECEIVED" : "PAID",
        modeOfPayment: PAYMENT_MODES[selectedPaymentModes],
        userType,
      };
      await createTransaction(data);
      handleCloseModal();
    } catch (error) {
      handleOpenToast("Error while creating transaction", "error");
    }
  };

  useEffect(() => {
    setAmountPaid(
      userType === "customer"
        ? tripData?.customerAmounts?.balanceReceivable
        : tripData?.transporterAmounts?.balancePayable
    );
  }, [tripData]);

  return (
    <Dialog open={isModalOpen} onClose={handleCloseModal} Width="625px">
      <DialogTitle sx={{ color: "#333" }}>
        <Typography fontWeight={700} fontSize={24}>
          {userType === "customer"
            ? "Settle Customer Payment"
            : "Settle Transporter Payment"}
        </Typography>
      </DialogTitle>
      <CloseIcon
        fontSize="medium"
        onClick={handleCloseModal}
        sx={{
          position: "absolute",
          right: "10px",
          top: "14px",
          cursor: "pointer",
        }}
      />
      <DialogContent>
        <Box
          sx={{
            gap: "40px",
            width: "500px",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <InputLabel
              sx={{
                color: "#333",
                fontSize: "1.125rem",
                fontWeight: 700,
              }}
              htmlFor="weight.min"
            >
              Amount Paid
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              type="number"
              width="100%"
              value={amountPaid}
              error={!!error}
              helperText={error}
              onChange={(e) => setAmountPaid(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">₹</InputAdornment>
                ),
              }}
            />
          </Box>
          <Box sx={{ width: "100%", marginTop: "20px" }}>
            <InputLabel
              sx={{
                color: "#333",
                fontSize: "1.125rem",
                fontWeight: 700,
              }}
              htmlFor="weight.min"
            >
              Payment mode
            </InputLabel>
            <StyledSelect
              name="paymentMode"
              value={selectedPaymentModes}
              onChange={handlePaymentModeChange}
              InputLabelProps={{ shrink: false }}
              variant="outlined"
              fullWidth
            >
              {PAYMENT_METHOD?.map((accessory, index) => (
                <MenuItem key={index} value={accessory}>
                  {accessory}
                </MenuItem>
              ))}
            </StyledSelect>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <StyledButton
            variant="contained"
            color="primary"
            type="submit"
            onClick={handleCreateTransaction}
          >
            Submit
          </StyledButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SettlePaymentModal;

const StyledSelect = styled(Select)(() => ({
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#ADADAD",
  },
  height: "57px",
  maxHeight: "57px",
}));

const StyledButton = styled(Button)(() => ({
  float: "right",
  padding: "10px 30px",
  fontSize: "1.2rem",
  margin: "20px 0",
}));
