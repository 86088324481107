import { Grid, Link, Typography } from "@mui/material";

const Declaration = () => (
  <Grid item xs={12}>
    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
      DECLARATION:
    </Typography>
    <Typography variant="body2" sx={{ mt: 1 }}>
      1. I/We hereby declare that though our aggregate turnover in any preceding
      financial year from 2017-18 onwards is more than the aggregate turnover
      notified under sub-rule (4) of rule 48, we are not required to prepare an
      invoice in terms of the provisions of the said sub-rule.
    </Typography>
    <Typography variant="body2" sx={{ mt: 0.5 }}>
      2. Inputs relating consignment, consignor and consignee information are
      provided by the user. We hereby declare that Input Tax Credit of Capital
      Goods, input and input Services, used for providing transportation
      services has not been taken by us. Please visit{" "}
      <Link href="https://loadeazy.com/terms&conditions" target="_blank">
        https://loadeazy.com/terms&conditions
      </Link>{" "}
      for applicable T&C
    </Typography>
  </Grid>
);

export default Declaration;
