import React from "react";
import { Box, Grid, Typography, Link } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import LanguageIcon from "@mui/icons-material/Language";
import PhoneIcon from "@mui/icons-material/Phone";
import LoadEazyQrCode from "./LoadEazyQrCode";

const ContactInformation = () => (
  <Box sx={{ p: 2, backgroundColor: "#F0F0F0", borderRadius: 2 }}>
    <Grid container spacing={2} alignItems="flex-start">
      <Grid item xs={2}>
        <LoadEazyQrCode />
      </Grid>
      <Grid item xs={3}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            p: 0,
          }}
        >
          <Typography
            variant="body2"
            sx={{ fontWeight: "bold", fontSize: "16px", mb: 1 }}
          >
            Juggernaut Ventures
          </Typography>
          <Typography variant="body2" sx={{ fontSize: "12px" }}>
            283, 5th Main, 5th Cross 1st Block Koramangala, Bangalore 560034
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={3.5}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            height: "100px",
            ml: 1,
          }}
        >
          <Typography variant="body2" sx={{ fontSize: "12px" }}>
            Signature
            <br />
            on behalf
          </Typography>
          <img
            src="/assets/icons/LZ-sign.png"
            alt="logo"
            style={{ height: "100px", width: "200px" }}
          />
        </Box>
      </Grid>
      <Grid item xs={3.5}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <Link
            href="mailto:help@porter.in"
            variant="body2"
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: "12px",
              gap: "8px",
            }}
          >
            <EmailIcon sx={{ fontSize: "12px" }} />{" "}
            <span>juggernautventures23@gmail.com</span>
          </Link>
          <Typography
            variant="body2"
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: "12px",
              gap: "8px",
            }}
          >
            <LanguageIcon sx={{ fontSize: "14px" }} />{" "}
            <span>https://loadeazy.com</span>
          </Typography>
          <Typography
            variant="body2"
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: "12px",
              gap: "6px",
            }}
          >
            <PhoneIcon sx={{ fontSize: "14px" }} /> <span>+91 7406951888</span>
          </Typography>
        </Box>
      </Grid>
    </Grid>
    <Box sx={{ mt: 2 }}>
      <Typography variant="body2" sx={{ fontSize: "12px" }}>
        GST category: Goods Transport Agency | GSTIN no. 29AAPFJ0411P1ZY | PAN:
        AAPFJ0411P | TAN: BLRJ09822B
      </Typography>
    </Box>
  </Box>
);

export default ContactInformation;
